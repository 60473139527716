import { Helmet } from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import {
  SplitContent,
  SplitFigure,
  Container,
  SplitSection,
  ReadMore,
  Text
} from '../components/styles'
import Arrow from '../components/Arrow'
import styled from 'styled-components'
import Hero from '../components/Hero'
import { Content } from '../components/procedures'

const Value = styled(Container).attrs({
  bg: '#fff',
  medium: true,
  shadow: true
})`
  margin-bottom: 1.5rem;
  padding: 3rem;

  > div > div:first-child {
    padding-right: 3rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 639px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`

const StyledHero = styled(Hero)`
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0;
`
const Info = styled.div`
  z-index: 10;
  background: rgba(255, 255, 255, 0.75);
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-top: 25vh;
  text-align: center;
  max-width: 600px;

  h3 {
    color: #000;
    text-shadow: none;
    @media (max-width: 500px) {
      font-size: 1.3rem;
    }
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    coast: {
      childImageSharp: {
        fluid: object
      }
    };
    ecology: {
      childImageSharp: {
        fluid: object
      }
    };
    top: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => (
  <Layout header="dark">
    <Helmet>
      <title>Rob Morgan - Psychologist - Upright Performance</title>
      <meta
        name="description"
        content="Meet Rob Morgan psychologist in Brisbane and director of Upright Performance. Come and work with Rob for support with mental health issues, positive behaviour support and agency services."
      />
    </Helmet>
    <StyledHero height="90vh" noForm={true} bgImage={data.top.childImageSharp}>
      <Info>
        <h3>Psychology & Change Solutions. Partnering With You.</h3>
      </Info>
    </StyledHero>

    <Container padY={true} medium={true}>
      <SplitSection>
        <SplitFigure>
          <Img
            fluid={data.meetSellars.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h2>Meet Rob Morgan</h2>
          <p>
            I started my working life in hospitality in Toowoomba and then moved
            to Central Queensland where I managed the family hotel for over 10
            years.
          </p>
          <p>
            I enrolled at university as a mature-age student and completed a
            Bachelor of Science (Psychology) and Post Graduate Diploma in
            Psychology to become a psychologist. During my studies, I
            volunteered and worked in a range of mental health support roles,
            learning the impacts impaired mental health can have on us. My first
            role as a psychologist was with Disability Services Queensland. I
            worked with children, adolescents and adults from all walks of life
            for 11 years across the greater Brisbane region. I also spent much
            of this time working with Child Protection clients. With the
            roll-out of the National Disability Insurance Scheme (NDIS) in
            Queensland, I finished as a Clinical Manager with the Disability
            Services (Department of Communities) and established Upright
            Performance.
          </p>
          <p>
            I am committed to my professional development, to ensure
            evidenced-based best practice. Access to professional networks
            allows for the ongoing review and evolution of my ethical and
            practical frameworks and staying up to date with the latest and
            evolving techniques.
          </p>

          <ReadMore to="/contact">
            Contact Rob Today
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
    </Container>

    <Content>
      <h4>Experience & Training</h4>
      <p>
        My professional development and support of mental health and disability
        services across government, schools and NGOs positions me well to help
        individuals and families with tailored disability, mental health, and
        coping solutions.
      </p>
      <h4>What makes Rob tick</h4>
      <p>
        I am married with two daughters. While studying, I was able to take on a
        primary care role for our children, allowing my wife to continue her
        career. Being active with my daughters’ schools and sporting clubs has
        gifted our family with great experiences and friendships. The many
        camping and fishing trips with school families have made special
        memories and lasting friendships.
      </p>
      <p>
        I have a long-held passion for motorcycles and have raced enduro and
        motocross around the country (not very well I might add!) I still enjoy
        riding and maintaining my bikes. Getting out in the bush on a dirt bike
        is still a buzz for me.
      </p>
      <p>
        I have a long history with sport as a player, coach and administrator.
        I’ve only recently retired after 30+ years of playing rugby union and
        have had extensive experience as a rugby coach and club administrator.
        The relationships I have developed and lessons I have learned as part of
        a team and playing a collision sport have carried with me throughout my
        working life. I also played rugby league, touch football, and basketball
        for many years. Sport and performance have been motivators for as long
        as I can remember.
      </p>
      <p>
        Having daughters drew me away from rugby coaching and I became exposed
        to, and then interested in netball. I’ve been coaching children and
        adults for over 10 years and am a life member of Brisbane Central Flyers
        Netball Club. As a coach, I focus on competitive spirit and working with
        what you have. The success I have had with many teams has grown from
        respect, clear expectation for performance and behaviour, and improving
        skills. In recent years, I have progressed through the ranks of Rhee
        Taekwon-Do to second dan black belt instructor. Rhee Taekwon-Do has a
        50-year history in Australia and promotes healthy living, respect,
        discipline, fitness and self-defence. I continue to progress my
        knowledge and skills as a student of World Master Chong Chul Rhee and
        have the privilege to pass this learning on to the children and adult
        students I teach at my RTKD branch. The values of Rhee Taekwon-Do have
        complemented my personal and work life immensely.
      </p>
      <p>
        Moving forward in psychology, helping where I can, remains a major
        motivator for me. I believe in the strength of healthy relationships and
        the power of people to make positive change in their lives. Sometimes we
        just need help to learn how to take those first steps.
      </p>
    </Content>
    <CTA
      inverted={true}
      title="Looking for psychological support?"
      subtitle="Rob's here to help you."
      button="Contact Rob Today"
    />
  </Layout>
)

export const query = graphql`
  query AboutPageQuery {
    top: file(relativePath: { eq: "rob-morgan-top.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    meetSellars: file(relativePath: { eq: "rob-motorbike.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    michelle: file(relativePath: { eq: "michelle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    julie: file(relativePath: { eq: "julie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    leanne: file(relativePath: { eq: "leanne.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    chris: file(relativePath: { eq: "chris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    kylie: file(relativePath: { eq: "kylie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    christine: file(relativePath: { eq: "christine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    team: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    teamTwo: file(relativePath: { eq: "sellars-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
